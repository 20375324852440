import React from "react";

export default function Logo() {
  return (
    <svg viewBox="0 0 164.17 154.49" height="30px">
      <defs>
        <style>{".prefix__cls-1{fill:#3abfef}"}</style>
      </defs>
      <path
        className="prefix__cls-1"
        d="M109.96 105.84a48.38 48.38 0 01-48.35 48.37H0V0h43.33a42.69 42.69 0 0138.62 62.21 48 48 0 0128.01 43.63zm-79.6-48.35h13a14.45 14.45 0 000-28.9h-13v28.9zm50.72 48.35a19.44 19.44 0 00-19.46-19.46H30.36v38.91h31.25a19.44 19.44 0 0019.47-19.45z"
      />
      <circle className="prefix__cls-1" cx={141.65} cy={131.97} r={22.52} />
    </svg>
  );
}
